<template>
  <div class="card-user-manage">
    <en-table-layout :tableData="tableData.data">
      <template slot="toolbar">
        <el-form-item label="创建时间">
          <el-date-picker v-model="order_time_range" type="daterange" style="width: 250px" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="handleDateChange" />
        </el-form-item>
        <el-form-item label="状态">
          <el-select style="width: 100px" v-model="params.status" size="medium" placeholder="请选择" class="choose-machine"
            clearable>
            <el-option label="全部" value=""></el-option>
            <el-option label="启用中" value="1"></el-option>
            <el-option label="已禁用" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input style="width: 270px;" size="small" placeholder="请输入关键字进行搜索" v-model.trim="params.keyWord" clearable>
            <el-select v-model="key_word" slot="prepend" style="width: 100px;">
              <el-option label="人员名称" value="name"></el-option>
              <el-option label="手机号码" value="mobile"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button native-type="submit" @click.stop.prevent="seachMember" size="small" type="primary">搜索</el-button>
          <el-button @click="handleEdit" size="small" type="primary">添加</el-button>
        </el-form-item>
      </template>
      <template slot="table-columns">
        <el-table-column label="人员名称" prop="name" show-overflow-tooltip fixed="left" />
        <el-table-column prop="mobile" label="手机号码" width="120" show-overflow-tooltip fixed="left" />
        <el-table-column prop="create_time" width="180" label="创建时间" show-overflow-tooltip fixed="left">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column prop="disabled" label="状态" width="90" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.status === 0 ? '已禁用' : '启用中' }}
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注信息" min-width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.remarks }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" min-width="100">
          <template slot-scope="scope">
            <div class="operate" style="white-space: nowrap; display: inline-block">
              <el-button type="primary" size="small" @click="openRemarkDialog(scope.row)">备注</el-button>
              <el-button v-if="scope.row.status == 0" type="primary" size="mini"
                @click="handleEdit(scope.row)">编辑</el-button>
              <el-button size="mini" type="primary" plain @click="handleDisable(scope.row)"
                v-if="scope.row.status == 0">
                启用
              </el-button>
              <el-button size="mini" type="danger" plain @click="handleDisable(scope.row)"
                v-if="scope.row.status !== 0">
                禁用
              </el-button>
              <el-button v-if="scope.row.status == 0" size="mini" type="danger"
                @click="handleDel(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" background :layout="MixinTableLayout"
        :page-size="tableData.page_size" :total="tableData.data_total" />
    </en-table-layout>
    <x-dialog :proxy="remarkDialog">
      <el-form ref="remarkForm" label-width="80px" :model="remarkForm" :rules="remarkFormRules">

        <el-form-item label="备注信息" prop="remarks">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" placeholder="请输入不超100字的备注信息" maxlength="100"
            v-model.trim="remarkForm.remarks"></el-input>
        </el-form-item>

      </el-form>
    </x-dialog>
  </div>
</template>

<script>
import * as API_Users from '@/api/users';
import EnTableLayout from '@/../ui-components/TableLayout/src/main';
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
export default {
  name: "cardOpeningPersonnelManagement",
  components: {
    EnTableLayout,
    XDialog
  },
  data () {
    return {
      remarkDialog: $xDialog.create({
        title: "备注信息",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: () => {
          return this.editRemark();

        },
      }),
      // 备注信息
      remarkForm: {
        id: "",
        remarks: ""
      },
      remarkFormRules: {
        remarks: [
          {
            required: true,
            message: '请输入备注信息',
            trigger: 'blur'
          },
        ]
      },
      order_time_range: [],
      key_word: 'name',
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        status: '',
        keyWord: "",
      },
      tableData: {
        data: []
      },
    };
  },
  created () {
    this.getList();
    API_Users.getUserGroups().then(res => {
      this.groupingOptions = [
        {
          group_id: '',
          group_name: '全部'
        },
        {
          group_id: 0,
          group_name: '暂无'
        },
        ...res
      ]
    });
  },
  activated () {
    this.getList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  methods: {
    // 表头部重新渲染
    renderHeader (h, { column, $index }) {
      setTimeout(() => {
        // 获取操作按钮组的元素
        const opts = document.getElementsByClassName('operate');
        let widthArr = [];
        // 取操作组的最大宽度
        Array.prototype.forEach.call(opts, function (item) {
          if (item.innerText || item.children) {
            widthArr.push(item.offsetWidth);
          }
        });
        // 重新设置列标题及宽度属性
        if (widthArr.length > 0) {
          column.width = Math.max(...widthArr) + 24;
        } else {
          column.width = 0;
        }
      }, 1)
      return h('span', column.label);
    },
    /**打开备注信息 */
    openRemarkDialog (row) {
      this.remarkDialog.display();
      this.remarkForm.id = row.id; //订单编号
      this.remarkForm.remarks = //订单备注
        row.remarks === null
          ? row.remarks
          : row.remarks.replaceAll("/n", "\n");

    },
    /**保存备注信息 */
    editRemark () {
      return new Promise((resolve) => {
        this.$refs["remarkForm"].validate((valid) => {
          if (valid) {
            this.remarkForm.remarks = this.remarkForm.remarks.replace(/\n/g, "/n");
            API_Users.memberOpenRemarks(this.remarkForm).then(res => {
              this.$message.success("保存成功");
              this.getList();
              resolve(true);
            });
          } else {
            // this.$message.error('表单填写有误，请检查！');
            resolve(false);
          }
        });
      })
    },
    //格式化时间控件
    handleDateChange () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.start_time =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.end_time =
            new Date(this.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start_time =
            this.order_time_range[0].getTime() / 1000;
          this.params.end_time =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.start_time = "";
        this.params.end_time = "";
      }
    },
    //编辑|添加
    handleEdit (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      if (row) {//编辑
        this.$router.push({
          name: "editCardUserManage",
          params: {
            id: row.id
          },
        });
      } else {//新增
        this.$router.push({
          name: "editCardUserManage",
        });
      }
    },
    /**搜索 */
    seachMember () {
      this.getList(1);
    },
    /**查询列表 */
    getList (page_no) {
      let params = {
        ...this.params
      };
      page_no && (params.page_no = 1);
      params[this.key_word] = params.keyWord;
      delete params.keyWord;
      API_Users.getMenberOpenlist(params).then((res) => {
        this.page_size = res.page_size;
        this.page_no = res.page_no;
        this.tableData = res;
      });
    },
    //禁用|启用
    handleDisable (row) {
      const statusName = row.status === 0 ? '启用' : '禁用';
      this.$confirm(`确定要${statusName}这个开卡人员吗?`, '提示', {
        type: 'warning',
      }).then(() => {
        const status = row.status === 0 ? 1 : 0;
        API_Users.closeMemberOpen(row.id, status).then((res) => {
          this.getList();
          this.$message.success(`${statusName}成功`);
        });
      });
    },
    //删除
    handleDel (row) {
      this.$confirm('确定要删除这个开卡人员吗？', '提示', {
        type: 'warning',
      }).then(() => {
        API_Users.delmemberOpen(row.id).then((res) => {
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    handleSizeChange (val) {
      this.params.page_size = val;
      this.getList();
    },
    handleCurrentChange (val) {
      this.params.page_no = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.userData {
  margin-top: 5px;

  .user-statistics {
    background-color: #FAFAFA;
    padding: 5px 10px;

    /deep/ p {
      font-size: 18px;
    }

    /deep/ span {
      color: #00dd9b;
      font-size: 18px;
      font-weight: normal;
    }
  }
}

.importok {
  h1 {}
}

.moreUser {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -300px;
  margin-left: -375px;
  background-color: #fff;
  width: 750px;
  height: 600px;
}

.domnload1 {
  cursor: pointer;
  color: #0079fe;
}

.domnload1:hover {
  color: red;
}

.shadow {
  position: absolute;
  z-index: 9999999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 1000px;
}

.xleft {
  cursor: pointer;
  float: right;
}

.first {
  font-size: 14px;
  padding: 0px 20px 0px 20px;
}

.secend h2 {
  text-align: center;
  float: left;
  width: 50%;
}

.threeone {
  margin: 20px 10px;
  border: 1px solid rgba(233, 233, 233, 1);
  padding: 18px 0px 10px 120px;
}

.threetwo {
  margin: 20px 10px;
  background-color: rgb(255, 235, 204);
  padding: 15px 20px 15px 60px;
}

.btn2 {
  padding-right: 10px;
  text-align: end;
}

.userAdimin {
  position: relative;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 5px;
}

.demo-ruleForm1 {
  z-index: 2000;
  background-color: #fff;
  position: absolute;
  top: 60px;
  left: 0;
  width: 300px;
  padding: 20px;
}

/deep/ .el-form-item__label {
  font-size: 12px;
}

/deep/ .el-input__inner {
  font-size: 12px;
}

/deep/ .el-range-input {
  font-size: 12px;
}

/deep/ .el-date-editor--datetimerange.el-input__inner {
  width: 168px;
}

.btn33 /deep/ .el-form-item__content {
  margin-left: 0px !important;
}

.posi {
  z-index: 99;
  position: absolute;
  left: 85px;
  top: 100px;
}

.search {
  padding-top: 10px;
}

/*
.fenye .block .el-pagination {
  padding: 10px 0px;
  text-align: center;
}


.search .el-form-item__content {
  width: 145px;
}
.search .el-form-item__content .el-input__inner {
  height: 26px;
}
/* .search .el-button--primary {
  height: 21px;
} */
.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}

/* .search .btn3 .el-form-item__content {
  width: 400px;
} */
/* .search .el-button {
  line-height: 0;
} */
/* .search .el-form {
  margin-bottom: 20px;
}
.tableList .is-leaf {
  text-align: center;
}
.tableList .el-table__body .el-table__row td .cell {
  text-align: center;
}
.tableList .el-table_1_column_1 .cell .el-checkbox__inner {
  background-color: #e6ecf7;
} */
</style>
